import './App.css'
import React from "react"
import {
  Routes,
  Route,
  NavLink
} from "react-router-dom"

import LogoMobile from './logoMobile.svg'
import LogoDesktop from './logoDesktop.svg'

import Home from './categories/0-home/Home'
import About from './categories/1-about/About'
import Trompetenbau from './categories/2-trompetenbau/Trompetenbau'
import Impressionen from './categories/3-impressionen/Impressionen'
import Video from './categories/4-videos/Video'
import Contact from './categories/5-contact/Contact'

export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  isDesktop = () => {
    const { width } = this.state;
    return width >= 800;
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  // make sure to remove the listener when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
  showNav = (x) => {
    // always refers to the element to which the event handler has been attached
    // x.currentTarget
    document.getElementById("burger-nav").classList.toggle("active")
    document.getElementById("main-nav").classList.toggle("active")
    document.getElementsByClassName("container")[0].classList.toggle("nav-active")
  }

  render() {
    var logo = this.isDesktop() ? LogoDesktop : LogoMobile;

    return (
      <div className="App">
        <div id="burger-nav" onClick={this.showNav}>
          <div className="bar1"></div>
          <div className="bar2"></div>
        </div>
        <div className="container-logo">
          <img src={logo} alt="Logo Mobile" className="logo" />
        </div>
        <nav id="main-nav">
          <ul>
            <li><NavLink exact to="/" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>home</NavLink></li>
            <li><NavLink to="/about" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>about</NavLink></li>
            <li><NavLink to="/trompetenbau" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>trompetenbau</NavLink></li>
            <li><NavLink to="/impressionen" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>impressionen</NavLink></li>
            <li><NavLink to="/video" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>video</NavLink></li>              
            <li><NavLink to="/contact" onClick={this.showNav} className={({ isActive }) => isActive ? 'selected' : ''}>contact</NavLink></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/about" element={<About/>} />
          <Route path="/trompetenbau" element={<Trompetenbau isDesktop={this.isDesktop()} />} />
          <Route path="/impressionen" element={<Impressionen/>} />
          <Route path="/video" element={<Video/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/" element={<Home/>} />
        </Routes>
      </div>
    )
  }
}

/*
        <footer>
          <p>kontakt: blaswerkstatt burri zimmerwald</p>
        </footer>

        */