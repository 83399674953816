import './About.css'

import MainComponent from '../../templates/MainComponent'

export default function About(props) {
  var contentKoni = {
    title: 'Konrad Burri',
    subtitle: 'Instrumentenbauer',
    img: [],
    ptext: <div>
      <p>Aufgewachsen in einer Instrumentenbaufamilie, kommt Konrad Burri schon früh mit der Welt der
      Blasinstrumente in Berührung. Seine Begeisterung für das Handwerk und sein Talent für die Suche
      nach kreativen Lösungen im Instrumentenbau bringt Ihn in seiner Laufbahn mit vielen international
      bekannten Musikern und Instrumentenherstellern zusammen. Sein handwerkliches Können
      vervollständigt er in Paris, Lugano und seit 1994 in Zimmerwald.
      </p>
    </div>
  }
  var contentYvonne = {
    title: 'Yvonne Burri',
    subtitle: 'Geschäftsführung, Administration',
    img: [],
    ptext: <div>
    </div>
  }

  return (
    <section id="about-container" className="container">
      <MainComponent content={contentKoni} />
      <MainComponent className="with-header-margin" content={contentYvonne} />
    </section>   
  );
}