import './Home.css'

import MainComponent from '../../templates/MainComponent'
import homeImg from '../../links/home/home.png'

export default function Home(props) {
  var content = {
    title: 'Trompetenbau',
    subtitle: 'mit Leidenschaft',
    img: [homeImg],
    ptext: <div>
              <p>Blaswerkstatt ist ein kleines Familienunternehmen in der Schweiz mit Sitz in Zimmerwald, in der
              Nähe von Bern.</p>
              <p>Es ist spezialisiert auf den Trompetenbau.</p>
              <p>Von der Barocktrompete, Klassiktrompete, Klappentrompeten, Kastenventiltrompete bis zur
              modernen Bb-Trompete wird alles in eigener Werkstatt hergestellt.</p>
          </div>
  }

  return (
    <section id="home-container" className="container">
      <MainComponent content={content} />
    </section>  
  );
}