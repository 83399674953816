import './Trompetenbau.css'

import MainComponent from '../../templates/MainComponent'

import bTrompete from '../../links/trompetenbau/b-trompete.png'
import klassiktrompete from '../../links/trompetenbau/klassiktrompete.jpg'
import klappentrompete from '../../links/trompetenbau/klappentrompete.jpg'
import klappentrompete1 from '../../links/trompetenbau/klappentrompete2.jpg'

export default function Trompetenbau(props) {
  var contentBTrompete = {
    title: 'Bb-Trompete',
    subtitle: 'Neues Modell',
    img: [bTrompete],
    ptext: <div>
            <p>Die selbstentwickelte Bb-Trompete besteht aus einer ganz neuen Rohrführung, damit die Distanz
            vom Ohr des Bläsers zum Schallstück der Trompete so kurz wie möglich ist.</p>
            <p>Auf diese Weise hört sich der Bläser viel besser und es ergibt sich daraus ein ausgezeichnetes
            Spielgefühl.</p>
            <p>Weiter hat die Trompete einen abschraubbaren Becher und ein auswechselbares Mundrohr.</p>
          </div>
  }
  var contentBarockTrompete = {
    title: 'Barocktrompete',
    subtitle: 'in 4 Stimmungen D/Des/C/Ces',
    img: [],
    ptext: ''
  }
  var contentKlassikTrompete = {
    title: 'Klassiktrompete',
    subtitle: 'in 4 Stimmunge D/Des/C/CEs',
    img: [klassiktrompete],
    ptext: ''
  }
  var contentKlappenTrompete = {
    title: 'Klappentrompete',
    subtitle: 'wow!',
    img: [klappentrompete, klappentrompete1],
    ptext: ''
  }
  var contentKastenTrompete = {
    title: 'Kastenventiltrompete',
    subtitle: '',
    img: [],
    ptext: <p>Foto folgt.</p>
  }

  return (
    <section id="trompeten-container" className="container">
      <div id="trompeten-einleitung">
        <p>Folgende Trompeten können in unserem Geschäft getestet werden, gerne können sie einen Termin
        mit uns vereinbaren. <br></br> Hergestellt werden die Trompeten auf Bestellung, Lieferzeit ca. 3- 6 Monate.</p>
      </div>
      <MainComponent content={contentBTrompete} className="with-header-margin" />
      <MainComponent content={contentBarockTrompete} className=" with-header-margin" />
      <MainComponent content={contentKlassikTrompete} />
      <MainComponent content={contentKlappenTrompete} />
      <MainComponent content={contentKastenTrompete} className=" with-header-margin" />
    </section>   
  );  
}