import './Video.css'

export default function Video(props) {
  return (
    <section className="container">
      <div className="video">
        <iframe title="Die neuentwickelte Klappentrompete" src="https://www.youtube.com/embed/MydPK7pH6dQ"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>
      <div className="video">
        <iframe title="The Keyed Trumpet – Newly Born" src="https://www.youtube.com/embed/uqgIBJ-XSPM"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>
      <div className="video">
        <iframe title="Markus Wuersch performs Haydn Trumpet Concerto in E flat major" src="https://www.youtube.com/embed/oLvWjvaxlDc"
          allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>

      <div className="video">
        <iframe title="Joseph Haydn: Trumpet Concerto. 2nd Movement: Andante" src="https://www.youtube.com/embed/R8QF3LrqKrA"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>

      <div className="video">
        <iframe title="Joseph Haydn: Trumpet Concerto. 3rd Movement: Allegro" src="https://www.youtube.com/embed/WzHR46yaveQ"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>

      <div className="video">
        <iframe title="Haydn: Trumpet Concerto 1st movement arranged for 8 keyed trumpets, 5 natural trumpets and soloist." src="https://www.youtube.com/embed/XTprrQYCBMU"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>

      <div className="video">
        <iframe title="Markus Würsch performs Hummel Trumpet Concerto in E Major" src="https://www.youtube.com/embed/9XkrFxPBLXQ"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>

      <div className="video">
        <iframe title="Fiala: Divertimento for Keyed Trumpet" src="https://www.youtube.com/embed/U2TjHz4_C5M"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" />
      </div>
    </section>   
  );
}