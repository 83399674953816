import './MainComponent.css';

export default function MainComponent(props) {
  let className = ''
  if (props.className !== undefined) {
    className = props.className
  }
  return (
    <div className={className + " main-component"}>
      <div className="main-component-img">
        {props.content.img.map(element => {
          return <img src={element} alt={element} />
        })}
      </div>
      <div className="main-component-text">
        <h1>{props.content.title}</h1>
        <h2>{props.content.subtitle}</h2>
        {props.content.ptext}
      </div>
    </div>
  );
}