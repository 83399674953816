import './Impressionen.css'

function importAll(r) {
  let images = {};
  r.keys().map( item => images[item.replace('./', '')] = r(item) );
  return images;
}

const images = importAll(require.context('../../links/impressionen/', false, /\.(png|JPG|jpe?g|svg)$/));

export default function Impressionen(props) {
  return (
    <section id="impression-container" className="container">
      <div className="img-left">
      <img src={images['kasten.JPG']} alt='' />
        <img src={images['impr0.JPG']} alt='' />
        <img src={images['impr1.JPG']} alt='' />
        <img src={images['impr2.JPG']} alt='' />
        <img src={images['impr3.JPG']} alt='' />
        <img src={images['impr4.JPG']} alt='' />
        <img src={images['impr5.JPG']} alt='' />
        <img src={images['impr6.JPG']} alt='' />
        <img src={images['impr7.JPG']} alt='' />
        <img src={images['impr8.JPG']} alt='' />
        <img src={images['impr9.JPG']} alt='' />
        <img src={images['impr10.JPG']} alt='' />
        <img src={images['impr11.JPG']} alt='' />
        <img src={images['impr12.JPG']} alt='' />
        <img src={images['ophikleide0.jpg']} alt='' />
      </div>
      <div className="img-right">
        <img src={images['kasten1.JPG']} alt='' />
        <img src={images['impr13.JPG']} alt='' />
        <img src={images['impr14.JPG']} alt='' />
        <img src={images['impr15.JPG']} alt='' />
        <img src={images['impr16.JPG']} alt='' />
        <img src={images['impr17.JPG']} alt='' />
        <img src={images['impr18.JPG']} alt='' />
        <img src={images['impr19.JPG']} alt='' />
        <img src={images['impr20.JPG']} alt='' />
        <img src={images['impr21.JPG']} alt='' />
        <img src={images['impr22.JPG']} alt='' />
        <img src={images['impr23.JPG']} alt='' />
      </div>
    </section>
  );
}