import './Contact.css'

export default function Contact(props) {
  return (
    <section className="container">
      <div id="map">
      <iframe title="Karte Blaswerkstatt" 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2727.1222318055184!2d7.469097515660186!3d46.88064384583548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e4817d5caf33d%3A0x77453f93ac9f9460!2sKirchstrasse%209%2C%203086%20Wald!5e0!3m2!1sen!2sch!4v1612705081851!5m2!1sen!2sch" 
        allow="fullscreen;" />
      </div>
      <div>
        <h2>blaswerkstatt burri</h2>
        <p>
          Konrad und Yvonne Burri <br />
          Kirchstrasse 9 <br />
          3086 Zimmerwald
          <br />
          <br />
          tel. +41 31 819 78 19 <br />
          e-mail. info@blaswerkstattburri.ch
        </p>
    </div>
    </section>   
  );
}